<template>
    <div class="wraper">
        <div class="wrap-mainer">

            <div class="numsidebar">
                <div class="sidebarmain">
                    <a-tabs tab-position="left" type="card" v-model="active_key" @change="callback">
                        <a-tab-pane :key="1" tab="核酸检测统计"></a-tab-pane>
                        <a-tab-pane :key="2" tab="委托单统计"></a-tab-pane>
                        <a-tab-pane :key="3" tab="样品统计"></a-tab-pane>
                        <a-tab-pane :key="4" tab="检测统计"></a-tab-pane>
                        <a-tab-pane :key="5" tab="仪器统计"></a-tab-pane>
                        <a-tab-pane :key="6" tab="试剂统计"></a-tab-pane>
                        <a-tab-pane :key="7" tab="人员统计"></a-tab-pane>
                    </a-tabs>
                </div>
            </div>

            <div class="list-right wrap-container">
                <div v-if="this.active_key === 1">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-button type="Default">近三天</a-button>
                                <a-button type="Default">近一周</a-button>
                                <a-button type="Default">近一个月</a-button>
                                <a-button type="danger">清空搜索</a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>日期</th>
                                    <th>采样数量</th>
                                    <th>送样数量</th>
                                    <th>检测数量</th>
                                    <th>审核数量</th>
                                    <th>阳性数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>2022-02-14</td>
                                    <td>300</td>
                                    <td>200</td>
                                    <td>500</td>
                                    <td>500</td>
                                    <td>0</td>
                                </tr>
                                </tbody>
                            </table>
                        </a-col>
                    </a-row>
                </div>
                <div v-if="this.active_key === 2">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-button type="Default">近三天</a-button>
                                <a-button type="Default">近一周</a-button>
                                <a-button type="Default">近一个月</a-button>
                                <a-button type="danger">清空搜索</a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <th>日期</th>
                                <th>委托单数量</th>
                                <th>审核数量</th>
                                <th>报告数量</th>
                                </thead>
                                <tbody>
                                <td>2022-02-14</td>
                                <td>6</td>
                                <td>2</td>
                                <td>0</td>
                                </tbody>
                            </table>
                        </a-col>
                    </a-row>

                </div>
                <div v-if="this.active_key === 3">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-button type="Default">近三天</a-button>
                                <a-button type="Default">近一周</a-button>
                                <a-button type="Default">近一个月</a-button>
                                <a-button type="danger">清空搜索</a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <th>日期</th>
                                <th>采集样品数量</th>
                                <th>接收样品数量</th>
                                <th>退回样品数量</th>
                                </thead>
                                <tbody>
                                <td>2022-02-14</td>
                                <td>6</td>
                                <td>2</td>
                                <td>0</td>
                                </tbody>
                            </table>
                        </a-col>
                    </a-row>

                </div>
                <div v-if="this.active_key === 4">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-button type="Default">近三天</a-button>
                                <a-button type="Default">近一周</a-button>
                                <a-button type="Default">近一个月</a-button>
                                <a-button type="danger">清空搜索</a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <th>日期</th>
                                <th>检测委托任务数量</th>
                                <th>检测项目数量</th>
                                </thead>
                                <tbody>
                                <td>2022-02-14</td>
                                <td>6</td>
                                <td>2</td>
                                </tbody>
                            </table>
                        </a-col>
                    </a-row>

                </div>
                <div v-if="this.active_key === 5">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-input placeholder="请输入仪器编号或名称"></a-input>
                                <a-button type="danger">清空搜索</a-button>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>仪器名称</th>
                                    <th>仪器型号</th>
                                    <th>仪器编号</th>
                                    <th>仪器数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>农药残留检测仪</td>
                                    <td>qy_202111161093</td>
                                    <td>AQ_1</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>农药残留检测仪</td>
                                    <td>qy_202111161093</td>
                                    <td>AQ_1</td>
                                    <td>1</td>
                                </tr>
                                </tbody>
                            </table>
                        </a-col>
                    </a-row>

                </div>
                <div v-if="this.active_key === 6">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-date-picker placeholder="请输入开始时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-date-picker placeholder="请输入结束时间" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                                <a-button type="Default">近三天</a-button>
                                <a-button type="Default">近一周</a-button>
                                <a-button type="Default">近一个月</a-button>
                                <a-button type="danger">清空搜索</a-button>
                                <span style="color:#ff525d">* 开始时间和结束时间相同显示当天数据</span>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>日期</th>
                                    <th>试剂种类</th>
                                    <th>试剂总数量</th>
                                    <th>超上限试剂数量</th>
                                    <th>超下限试剂数量</th>
                                    <th>临期试剂数量</th>
                                    <th>失效试剂数量</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>2022-02-14</td>
                                    <td>12</td>
                                    <td>1500</td>
                                    <td style="color: #52c41a">10</td>
                                    <td style="color: #ff525d">6</td>
                                    <td style="color: #ff525d">2</td>
                                    <td style="color: #ff525d">1</td>
                                </tr>

                                </tbody>
                            </table>
                        </a-col>
                    </a-row>

                </div>
                <div v-if="this.active_key === 7">
                    <a-row>
                        <a-col :span="16">
                            <a-space>
                                <a-input placeholder="请输入人员姓名或编号"></a-input>
                                <a-button type="danger">清空搜索</a-button>
                            </a-space>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="16">
                            <table class="layui-table" lay-size="sm">
                                <thead>
                                <tr>
                                    <th>部门</th>
                                    <th>人员数量</th>
                                    <th>人员姓名</th>
                                    <th>人员编号</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td rowspan="3">超级管理员</td>
                                    <td rowspan="3">3</td>
                                    <td>王墩墩</td>
                                    <td>qy_202111161093</td>

                                </tr>
                                <tr>
                                    <td>鲁墩墩</td>
                                    <td>qy_202111161093</td>
                                </tr>
                                <tr>
                                    <td>陈墩墩</td>
                                    <td>qy_202111161093</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">普通管理员</td>
                                    <td rowspan="3">3</td>
                                    <td>王墩墩</td>
                                    <td>qy_202111161093</td>
                                </tr>
                                <tr>
                                    <td>王墩墩</td>
                                    <td>qy_202111161093</td>
                                </tr>
                                <tr>
                                    <td>王墩墩</td>
                                    <td>qy_202111161093</td>
                                </tr>
                                </tbody>
                            </table>
                        </a-col>
                    </a-row>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                active_key: 1,
            };
        },
        methods: {
            callback(key) {
                console.log(key);
            },
        },
    };
</script>

<style>
    .layui-table th{text-align: left}

    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{
        padding-left: 220px;

        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .addbutton{
        width: 100%;
        text-align: center;
        margin-left: -5px;
        }

    .wraper{
        width: 100%;
        height: 100%;
        display: flex;
        }

    .wrap-mainer{
        flex: 1;
        display: flex;
        }

    .numsidebar{
        width: 200px;
        display: flex;
        flex-direction: column;
        background: #f9f9f9;
        padding: 10px;
        padding-right: 0;
        height: calc(100vh - 120px);
        position: fixed;
        }

    .sidebarmain{
        flex: 1;
        overflow-y: auto
        }

    .wrap-container{
        flex: 1;
        overflow-y: auto
        }
</style>
